import { PageStats } from '../../../model/PageStats/PageStats';
import { Http } from '@angular/http';
import { TranslateService } from './../../../services/Translate/translate.service';
import { KOTagsService } from './../../../services/KnowledgeOwl/ko-tags.service';
import { KOArticleSearchService } from './../../../services/KnowledgeOwl/ko-article-search.service';
import { NoteService } from '../../../services/FormatsDB/notes.service';
import { KOUserService } from '../../../services/KnowledgeOwl/kouser.service';
import { BookmarkService } from '../../../services/FormatsDB/bookmarks.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, NgZone } from '@angular/core';
import { Article } from 'src/app/model/KnowledgeOwl/Article';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UserNotificationsService } from 'src/app/services/UserNotifications/user-notifications.service';
import { AppSettingsService } from 'src/app/services/Settings/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-search-result',
  templateUrl: './search-result.component.html',
  styleUrls: ['./search-result.component.css']
})
export class SearchResultComponent implements OnInit {
  _articleList: Article[];
  ShowKOArticleModal = false;
  ShowArticleUrl = "";
  DisplayNote = "none";
  currentItem: number;
  viewMoreItem: number;
  viewMoreItemPrevious = -1;
  NoteText = "";
  userId: string;
  DisplayViewMore = "inline-block"
  DisplayViewLess = "none"
  viewMoreSelected = false;
  ViewModeLabels = ["View More", "View Less"];
  ViewMode = this.ViewModeLabels[0];
  articleBody = "";
  Loading = false;
  loadingText = ""
  CSS_CodePre = ""
  articlesMultiCause: articleMC[][] = []
  sabreCentralActive = false;

  @Input() set Articles(value: Article[]) {
    if (!this.userId && this.koUserService.getCurrentUser()) {
      this.userId = this.koUserService.getCurrentUser().USER_ID;
    }

    if (value && value != undefined) {
      this._articleList = value;
      // console.log('_articleList', this._articleList)

      for (let i = 0; i < this._articleList.length; i++) {
        if (this._articleList[i].isMultiCause) {
          // console.log("call loadArticle from input")
          this.loadArticle(i);
        }

      }
    }
  }

  constructor(
    private bookmarkService: BookmarkService,
    private noteService: NoteService,
    private koTagsService: KOTagsService,
    private koArticleService: KOArticleSearchService,
    private koUserService: KOUserService,
    private translate: TranslateService,
    private http: Http,
    public sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private userNotificationService: UserNotificationsService,
    private appSettingsService: AppSettingsService
    ) { }




  ngOnInit() {
    $('.spark-expand').sparkExpand();
    $('.spark-popover').sparkPopover();
    $('.spark-input').sparkTextInput();


    this.http.get("assets/css/code-pre.html")
      .subscribe(data => {
        if (data.text()) {
          this.CSS_CodePre = data.text()
          // console.log("this.CSS_CodePre ", this.CSS_CodePre)
        }
      });
    if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled").indexOf('True') >=0){
      this.sabreCentralActive = true;
    }
  }

  ngOnChanges(event: SimpleChange) {
    this.loadingText = this.translate.instant("Loading") + "..."
    this.articlesMultiCause.length = 0

    if (this._articleList.length == 1) {
      this.expandViewMore(0)
    } else {
      this.DisplayNote = "none";
      this.currentItem = null;
      this.viewMoreItem = null;
      this.NoteText = "";
      this.DisplayViewMore = "inline-block"
      this.DisplayViewLess = "none"
      this.viewMoreSelected = false;
      this.ViewMode = this.ViewModeLabels[0];
    }

  }

  createBookmark(article: Article) {
    let pageId = article.id;
    let type = 'A';
    if (article.bookmark) {
      this.bookmarkService.deleteBookmark(this.userId, pageId, type);
      article.bookmark = false;
    } else {
      this.bookmarkService.createBookmark(this.userId, pageId, type);
      article.bookmark = true;
    }
  }

  saveNote() {
    let pageId = this._articleList[this.currentItem].id.toString();
    let type = 'A';

    if (this._articleList[this.currentItem].hasNote) {
      this.noteService.editNote(this.userId, pageId, type, this.NoteText);
    } else {
      this.noteService.createNote(this.userId, pageId, type, this.NoteText);
      this._articleList[this.currentItem].hasNote = true
    }

    this._articleList[this.currentItem].note = this.NoteText;

    this.closeNote();
  }

  deleteNote() {
    let pageId = this._articleList[this.currentItem].id.toString();
    let type = 'A';

    if (this._articleList[this.currentItem].hasNote) {
      this.noteService.deleteNote(this.userId, pageId, type);
      this._articleList[this.currentItem].hasNote = false
      this._articleList[this.currentItem].note = ""
    }
    this.closeNote();
  }

  closeNote() {
    this.currentItem = null;
    this.NoteText = ""
  }

  openNote(article: Article, i: number) {
    this.DisplayNote = "block"
    this.currentItem = i
    this.NoteText = article.note
  }

  viewMore(i: number) {
    // console.log("start this.viewMoreItemPrevious ", this.viewMoreItemPrevious, " this.viewMoreItem ", this.viewMoreItem, " i ", i)

    // current card
    if (this.viewMoreItemPrevious == i) {
      if (this.ViewMode == this.ViewModeLabels[0]) {
        this.expandViewMore(i)
      } else {
        this.collapseViewMore(i)
      }
    } else {
      this.collapseViewMore(i)
      this.expandViewMore(i)
    }
    // console.log("end this.viewMoreItemPrevious ", this.viewMoreItemPrevious, " this.viewMoreItem ", this.viewMoreItem)
  }

  collapseViewMore(i: number) {
    this.DisplayViewMore = "inline-block"
    this.DisplayViewLess = "none"
    this.viewMoreItem = i
    this.viewMoreSelected = false;
    this.ViewMode = this.ViewModeLabels[0];
  }

  expandViewMore(i: number) {
    this.viewMoreItemPrevious = i

    this.DisplayViewMore = "none"
    this.DisplayViewLess = "inline-block"
    this.viewMoreItem = i
    this.viewMoreSelected = true;
    this.ViewMode = this.ViewModeLabels[1];
    if (!this._articleList[i].body || this._articleList[i].body == undefined) {
      this.loadArticle(i);
    } else {
      this.articleBody = this._articleList[i].body
    }

  }

  loadArticle(i: number) {
    if (!this._articleList[i].body || this._articleList[i].body == undefined) {
      // console.log("loadArticle ", i)
      this.articleBody = "<br><b>" + this.loadingText + "</b>"
      this._articleList[i].body = this.loadingText;
      this.Loading = true;
      this.koArticleService.getArticle(this._articleList[i].id)
        .subscribe(
          response => {
            this._articleList[i].body = this.OnGetKoBodyArticles(response) + this.CSS_CodePre
            //+'<style type="text/css">code{border:0px;padding:0px;margin:0px;-moz-border-radius:0px;-webkit-border-radius:0px;border-radius:0px}pre,code{font-family:Consolas,"Liberation Mono",Courier,monospace;color:#333;background:#f8f8f8;-moz-border-radius:3px;-webkit-border-radius:3px;border-radius:3px; border: 1px solid #eaeaea;}</style>'
            // this._articleList[i].body = this.OnGetKoBodyArticles(response)+'<style type="text/css">'+code_pre+'</style>'

            this.articleBody = this.loadArticleBody(this._articleList[i].body, i, false, 0);
            this.Loading = false;
          },
          error => {
            this._articleList[i].body = "NOT FOUND",
            this.OnError(error)
          }
        );
    }
  }

  OnGetKoBodyArticles(article): string {
    let body = "";
    if (article.current_version.en.text) body = article.current_version.en.text;

    return body;
  }

  viewLess(i: number) {
    this.DisplayViewMore = "inline-block"
    this.DisplayViewLess = "none"
    this.viewMoreItem = i
    this.viewMoreSelected = false;

    this.viewMoreItemPrevious = i
  }

  getViewMode(i: number): string {

    let text: string;
    if (i == this.viewMoreItem) {
      text = this.ViewMode
    } else {
      text = this.ViewModeLabels[0]
    }

    return text;

  }

  loadArticleBody(bodyHTML: string, card: number, loadSubArticle: boolean, indexMC: number): string {
     console.log("enter loadArticleBody ", bodyHTML)
    let body = bodyHTML;
    let bodyChanged = false

    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(body, 'text/html');
    let aElements = parsedHtml.getElementsByTagName("a");
    // console.log("length ", this.articlesMultiCause[card].length)
    if (!this.articlesMultiCause[card]) {
      this.articlesMultiCause[card] = new Array();
    }
    // console.log("aElements ",aElements)
    if (aElements && aElements.length > 0) {
      console.log("parseHtml: ", parsedHtml)
      for (let i = 0; i < aElements.length; i++) {
        if (aElements[i].parentElement.tagName == "LI") {
          bodyChanged = true
          // console.log("aElements: ", aElements[i].parentElement.tagName)
          // console.log("aElements: ", aElements[i].text)
          // console.log("aElements: ", aElements[i].pathname)
          let url = aElements[i].pathname.split("/help/")

          let el = new articleMC(aElements[i].text, '', false, false, [], [], url[1], [])
          // console.log (" el object ",el)
          if (loadSubArticle) {
            this.articlesMultiCause[card][indexMC].subArticles.splice(this.articlesMultiCause[card][indexMC].subArticles.length, 0, el)
          } else {
            this.articlesMultiCause[card].splice(this.articlesMultiCause[card].length, 0, el)
          }
        }
      }
      // console.log("MC ", this.articlesMultiCause)
      // console.log("bodyChanged ", bodyChanged)
      if (bodyChanged) body = ""
    }
    if (body != "") {
      body = this.checkForHref(body)
    }
    return body;
  }

  viewAMC(card: number, articleMC: number) {
    // console.log("card ", card, "articleMC", articleMC)
    this.articlesMultiCause[card][articleMC].expanded = !this.articlesMultiCause[card][articleMC].expanded
    for (let i = 0; i < this.articlesMultiCause[card].length; i++) {
      if (i != articleMC) {
        this.articlesMultiCause[card][i].expanded = false;
      }
    }
    // console.log("Loading  card ", card, " articleMC ", articleMC, " body ", this.articlesMultiCause[card][articleMC].body)
    if ((!this.articlesMultiCause[card][articleMC].body || this.articlesMultiCause[card][articleMC].body == undefined)
      && this.articlesMultiCause[card][articleMC].subArticles.length == 0) {
      // console.log("Loading  body")

      this.articlesMultiCause[card][articleMC].body = "<br><b>" + this.loadingText + "</b>"
      this.articlesMultiCause[card][articleMC].loading = true
      this.loadMultiCauses(card, articleMC, this.articlesMultiCause[card][articleMC].url_hash, -1);
    }
  }

  loadMultiCauses(card: number, articleMC: number, url: string, currentSubMCIndex: number) {
    let art: any;
    this.koArticleService.getArticleByURL(url)
      .subscribe(
        response => {
          art = response[0];
          if (art) {
            // console.log("art ", art, " currentSubMCIndex ", currentSubMCIndex)
            let body = "";
            if (art.current_version.en.text) body = art.current_version.en.text;
            // it doesn't have subArticles
            if (currentSubMCIndex == -1) {
              // console.log("isMultipleCause ", this.koArticleService.isMultipleCause(art.tags))
              this.articlesMultiCause[card][articleMC].isArticle = !this.koArticleService.isMultipleCause(art.tags)
              let loadSubArticle = !this.articlesMultiCause[card][articleMC].isArticle

              if (this.articlesMultiCause[card][articleMC].isArticle) {
                this.articlesMultiCause[card][articleMC].body = this.checkForHref(body)
              } else {
                this.articlesMultiCause[card][articleMC].body = this.loadArticleBody(body, card, loadSubArticle, articleMC)
              }
              this.articlesMultiCause[card][articleMC].tags = art.tags
              if (art.tags) {
                for (let i = 0; i < art.tags.length; i++) {
                  this.articlesMultiCause[card][articleMC].tagsname.push(this.koTagsService.getTag(art.tags[i]).name)
                }
              }
              this.articlesMultiCause[card][articleMC].loading = false
            } else {
              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].isArticle = true
              let loadSubArticle = !this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].isArticle

              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].body = this.loadArticleBody(body, card, loadSubArticle, articleMC)
              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].tags = art.tags
              if (art.tags) {
                for (let i = 0; i < art.tags.length; i++) {
                  this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].tagsname.push(this.koTagsService.getTag(art.tags[i]).name)
                }
              }
              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].loading = false
            }
          } else {
            this.articlesMultiCause[card][articleMC].body = "Article not found!"
          }
        },
      ),
      error => {
        this.OnError(error)
      };
    // console.log("this.articlesMultiCause[card][articleMC] ", this.articlesMultiCause[card][articleMC])
  }

  viewSubAMC(card: number, articleMC: number, currentSubMCIndex: number) {
    // console.log("card ", card, "articleMC", articleMC, " currentSubMCIndex ", currentSubMCIndex)
    this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].expanded = !this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].expanded
    for (let i = 0; i < this.articlesMultiCause[card][articleMC].subArticles.length; i++) {
      if (i != currentSubMCIndex) {
        this.articlesMultiCause[card][articleMC].subArticles[i].expanded = false;
      }
    }
    // console.log("Loading  card ", card, " articleMC ", articleMC, " currentSubMCIndex ", currentSubMCIndex, " body ", this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].body)
    if (!this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].body ||
      this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].body == undefined) {
      // console.log("Loading  body")
      this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].loading = true
      this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].body = "<br><b>" + this.loadingText + "</b>"
      this.loadMultiCauses(card, articleMC, this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].url_hash, currentSubMCIndex);
    }
  }

  checkForHref(body: string): string {
    let stringHRef = '<a href'
    let hRefArray = body.split(stringHRef)
    // console.log("hRefArray[0] ",hRefArray[0])
    let changedBody = hRefArray[0]
    for (let i = 1; i < hRefArray.length; i++) {
      let stringToReplace = hRefArray[i]
      // console.log("stringToReplace ",stringToReplace)

      changedBody += '<a target="_blank" href'
      // get the url from the string
      let URLArray = stringToReplace.split('"')

      let url = URLArray[1].split("quick.knowledgeowl.com/help/")
      // console.log("url[0]",url[0])
      // console.log("url[1]",url[1])

      if (url.length > 1) {
        stringToReplace = stringToReplace.replace(URLArray[1], this.createLinkToSFDC(url[1]))
      }
      // console.log("stringToReplace modified",stringToReplace)
      changedBody += stringToReplace
    }

    // console.log("changedBody ", changedBody)
    return changedBody
  }
  containsLinks(bodyHTML: string): boolean {
    // console.log("containsLinks  ", bodyHTML)

    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(bodyHTML, 'text/html');
    let aElements = parsedHtml.getElementsByTagName("a");
    // console.log("aElements ",aElements)
    if (aElements && aElements.length > 0) {
      // console.log("parseHtml: ", parsedHtml)
      for (let i = 0; i < aElements.length; i++) {
        if (aElements[i].parentElement.tagName == "LI") {
          return true;
        }
      }
    }
    return false;
  }


  closeKOArticleModal() {
    this.ShowKOArticleModal = false;
  }

  openKOArticleModal(article: Article) {
    this.ShowArticleUrl = 'https://quick.knowledgeowl.com/help/' + article.url_hash;
    this.ShowKOArticleModal = true;
    // console.log('Showing ' + article.name + ' in modal');
  }

  viewMoreInSalesforceCommunity(article: Article) {
    if (this.sabreCentralActive){
      window.open(this.createLinkToSFDC(article.url_hash), '_blank');
    }
  }

  createLinkToSFDC(param: string): string {
    let url;
    if (param == null){
      url = environment.SFDCURL + "finder" + "?mode=embedded";
    }
    else{
      url = environment.SFDCURL +"article/"+ param + "?mode=embedded";
    }
    //console.log('SFDC url ', url)
    return url
  }

  OnError(error: any) {
    this.displayWarningToast();
  }

  displayWarningToast() {
    const notification = this.userNotificationService.createWarningNotification(
      this.translateService.instant('Error'),
      this.translateService.instant('Cannot display the Article. Please try again in a moment'));

    this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
  }

}

class articleMC {
  text: string;
  body: string;
  expanded: boolean;
  isArticle: boolean;
  tags: string[];
  tagsname: string[];
  url_hash: string;
  subArticles: articleMC[];
  loading: boolean;

  constructor(text, body, expanded, isArticle, tags, tagsname, url_hash, subArticles) {
    this.text = text;
    this.body = body;
    this.expanded = expanded
    this.isArticle = isArticle
    this.tags = tags
    this.tagsname = tagsname
    this.url_hash = url_hash
    this.subArticles = subArticles
    this.loading = false
  }

}
