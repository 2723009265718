import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
import { AppSettings } from 'src/app/model/FormatDB/AppSettings';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  private _apiUrl = "";
  private appSettingsIndex = {} as IAppSettings;
  private apiTO = environment.apiTimeOut;


  constructor(
    private _koHelperService : KnowledgeOwlHelperService,
    private http: Http
    ) {
    this._apiUrl = _koHelperService.getUrl();
  }


   /**
   * Gets all App Settings
   * @param
   */
  public getAppSettings() {
    this.loadAppSettings()
  .subscribe(settings=> this.processAppSettings(settings));
  }

  public getSpecificAppSetting(name: string): string{
    for (const key in this.appSettingsIndex) {
      if (name.indexOf(this.appSettingsIndex[key].NAME) !== -1) {
        return this.appSettingsIndex[key].VALUE;
      }
    }
    return null;
  }

  /**
   * Process the json with all app settings and populate the settings dictionary
   * @param errors
   */
  private processAppSettings(settings: AppSettings[]) {

    console.log('settings', settings)
    settings.forEach((a: any) => {
      this.appSettingsIndex[a.NAME] = a
     });

     console.log('All settings', this.appSettingsIndex)
  }

  private loadAppSettings() {
    let url = this._apiUrl + '/getAppSettings';
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractData(res: Response) {
    if (res.text()) {
      //console.log(res.json());
        return res.json().body.data;
    } else {
      return {};
    }
  }
;


  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
      console.log('error del handleerror: ' +error);
      return Observable.throw(errMsg);
  }
}

interface IAppSettings {
  [index: string]: AppSettings;
}
