import { ELKSearchService } from '../../../services/ELKFormatSearchService/elkformat-search.service';
import { SearchPaginationService } from '../../../services/SearchPagination/search-pagination';
import { SearchPagination } from '../../../model/SearchPagination/SearchPagination';
import { PageStats } from '../../../model/PageStats/PageStats';
import { TranslateService } from '../../../services/Translate/translate.service';
import { FormatSearchSelected } from '../../../services/FormatsDB/format-search.service';
import { Format } from '../../../model/FormatDB/Format';
import { Component, OnInit, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { FormatSearchService } from 'src/app/services/FormatsDB/format-search.service';
import { NoteService } from '../../../services/FormatsDB/notes.service';
import { BookmarkService } from 'src/app/services/FormatsDB/bookmarks.service';
import { SabreRedMessagesService } from 'src/app/services/SabreRedMessages/sabre-red-messages.service';
import { UserNotificationsService } from 'src/app/services/UserNotifications/user-notifications.service';
import { ErrorArticle } from 'src/app/model/FormatDB/ErrorArticle';

declare var $: any;

@Component({
  selector: 'app-error-search',
  templateUrl: './error-search.component.html'
})
export class ErrorSearchComponent implements OnInit {
  @Output() IsLoading = new EventEmitter();
  @Output() OnSearchResultsErrorCreated = new EventEmitter();
  @Output() OnSearchResultsElapsedTimeErrors = new EventEmitter();
  // @Output() OnSearchResultsFavoriteMode = new EventEmitter();
  @Output() OnSearchErrorsPageStats = new EventEmitter();

  public _isLoading = false;
  private intialTimestamp: number
  private finalTimestamp: number
  private elapsedTime: string
  ShowFavorites = false
  DescriptionSearch = false
  SearchValue = "";
  searchMode = FormatSearchSelected.Start_With;
  searchParam = 'endsWith'
  _limit = 10
  _page = 1

  constructor(private formatSearchService: FormatSearchService,
    private elkSearchService: ELKSearchService,
    private bookmarkService: BookmarkService,
    private noteService: NoteService,
    private sabreRedMessagesService: SabreRedMessagesService,
    private searchPaginationService: SearchPaginationService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private userNotificationService: UserNotificationsService
  ) { }

  ngOnInit() {
    $('.spark-input').sparkTextInput();
    $('.spark-toggle-switch').sparkToggleSwitch();

    this.sabreRedMessagesService.currentTextSearchFormat.subscribe(message => {
      if (message && message != '') {
        this.SearchValue = message
        // console.log("Search Format Value ", this.SearchValue)
        this.search();
      }
    });
    this.translateService.currentLanguageChange
      .subscribe(language => {
          if (this.SearchValue != '' && this.SearchValue != null) {
            this.search()
          }
    });

    this.searchPaginationService.currentSearchPaginationErrors.subscribe(
      searchPagination => this.OnSearchPaginationRequest(searchPagination)
    );

  }
  OnSearchPaginationRequest(searchPagination: SearchPagination) {
    // console.log("searchPagination Formats search ", searchPagination)
    if (searchPagination && searchPagination.limit) {
      this.search(searchPagination.limit, searchPagination.page);
    }
  }

  /**
   * Search Method
   */
  search(limit?: number, page?:number) {
    // console.log("limit ", limit, " page ", page)
    // console.log("this._limit ", this._limit, " this._page ", this._page)
    let page_stats = new PageStats
    page_stats.total_records = 0
    this.OnSearchErrorsPageStats.emit(page_stats);
    this.OnSearchResultsElapsedTimeErrors.emit("0");

    if (limit==undefined) {
      limit = this._limit
    } else {
      this._limit = limit
    }
    if (page==undefined) {
      page = this._page
    } else {
      this._page = page
    }

    if (this.SearchValue != "") {
      this.ShowFavorites = false;
      this.intialTimestamp = Date.now();

      this._isLoading = true;
      this.IsLoading.emit(this._isLoading);

        this.elkSearchService.searchError(this.SearchValue, limit, page)
          .subscribe(
            response => {
              // response.data.forEach(f => {
              //   let regex = new RegExp(this.SearchValue, 'ig');
              //   f.DESCRIPTION = f.DESCRIPTION.replace(regex, function (x) {
              //     return '<span style="background-color:#FFFF00;">'+x+'</span>';
              //   })
              //   console.log("f.DESCRIPTION ", f.DESCRIPTION)
              // });
              console.log("response", response)
              this.OnGetErrors(response.data, response.page_stats)
            },
            error => this.OnGetErrorsError(error)
          );
    }
  }

  setSearchParam(option: string) {
    this.searchParam = option
    switch (option) {
      case 'startsWith':
        this.searchMode = FormatSearchSelected.Start_With;
        break;
      case 'endsWith':
        this.searchMode = FormatSearchSelected.End_With;
        break;
      case 'contains':
        this.searchMode = FormatSearchSelected.Contains;
        break;
    }
  }

  OnGetErrors(errors: ErrorArticle[], page_stats: PageStats) {
       console.log(" errors loaded", errors)

    this._isLoading = false;
    errors.forEach(f => {
      // this.loadFormatData(f)

      // let summary = f.DESCRIPTION.replace(/<[^>]*>/g, '');
      // // console.log(" summary ", summary)
      // f.EXAMPLE = summary

      if (f.EXAMPLE.length > 250) {
         f.EXAMPLE = f.EXAMPLE.substring(0,250) +"..."
      }

      // console.log(" EXAMPLE ", f.EXAMPLE)

    });

    console.log("errors ", errors)
    this.finalTimestamp = Date.now();

    this.elapsedTime = ((this.finalTimestamp - this.intialTimestamp) / 1000).toFixed(2);
    this.OnSearchErrorsPageStats.emit(page_stats);

    this.OnSearchResultsErrorCreated.emit(errors);
    this.OnSearchResultsElapsedTimeErrors.emit(this.elapsedTime);
    this.IsLoading.emit(this._isLoading);
    // this.OnSearchResultsFavoriteMode.emit(false);
  }


  OnGetErrorsError(error: any) {
    this._isLoading = false;
    this.IsLoading.emit(this._isLoading);
    this.displayWarningToast();
  }

  displayWarningToast() {
    const notification = this.userNotificationService.createWarningNotification(
      this.translateService.instant('Error'),
      this.translateService.instant('The search did not work. Please try again in a moment'));

    this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
  }

  @HostListener('document:keyup', ['$event'])
  handleInput(event: KeyboardEvent) {
    //console.log("key value " + event.key)
    switch (event.key){
      case '\'':
          this.SearchValue = this.SearchValue.replace('\'','¥');
      break;
      case '[':
          this.SearchValue = this.SearchValue.replace('[', '¤');
      break;
      case '=':
          this.SearchValue = this.SearchValue.replace('=', '*');
      break;
      case '\\':
          this.SearchValue = this.SearchValue.replace('\\', '§');
      break;
      case '`':
          this.SearchValue = this.SearchValue.replace('`', '^');
      break;
      case ']':
          this.SearchValue = this.SearchValue.replace(']', '¶');
      break;
    }
  }

}
