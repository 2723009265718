
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { Settings } from 'src/app/model/FormatDB/Settings';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private _apiUrl = "";
  private settings = new Settings;
  private apiTO = environment.apiTimeOut;


  constructor(private _koHelperService : KnowledgeOwlHelperService,private http: Http) {
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
   * Gets Settings for an user
   * @param userId
   */
  public getSettings() : Settings {
    return this.settings;
  }

  public setSettings(settings : Settings) {
     this.settings = settings;
  }

  /**
   * Load Settings for an user
   * @param userId
   */
  public loadSettings( userId: string): Observable<Settings> {
    let url = this._apiUrl + '/getSettings?';
    url += "USER_ID=" + userId;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Check if the Bookmark exists or not
   * @param id
   */
   public isErrorListener(id: string): boolean {
    return this.settings.ERRORLISTENER;
  }

  /**
 * Edit Settings for the user
 * @param userId
 * @param errorListener
 */
  public editSettings(userId: string, errorListener: boolean) {
    let url = this._apiUrl + '/editSettings';
    const body = {
      'USER_ID': userId,
      'ERRORLISTENER': errorListener
    };
    console.log (' new settings ', body)
    return this.http.post(url, body, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractDataEdit)
    .catch(this.handleError);
  }

  private extractDataEdit(res: Response) {
    if (res.text()) {
      // console.log("res ", res.json().statusCode)
      return res.json().statusCode == 200;
    } else {
      return false;
    }
  }

  /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractData(res: Response) {
    let result = new Settings
    result.ERRORLISTENER = true;
    if (res.text()) {
      // console.log("res settings", res.json())
      if (res.json().body.data[0]) {
          result  = res.json().body.data[0]
          // result.ERRORLISTENER = res.json().body.data[0].ERRORLISTENER==1?true : false
      }
      // console.log ('result ', result)

      this.settings = result
      // console.log ('this.settings ', this.settings)
      return result;
    } else {
      this.settings = result
      return result
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}
