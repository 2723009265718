import { KOArticleSearchService } from '../KnowledgeOwl/ko-article-search.service';
import { ResponseToIgnore } from '../../model/FormatDB/ResponseToIgnore';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/Rx';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import {KnowledgeOwlHelperService} from '../knowledge-owl-helper.service';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';
import { ELKSearchService } from '../ELKFormatSearchService/elkformat-search.service';

@Injectable({
  providedIn: 'root'
})

export class ResponseToIgnoreService {

  private _apiUrl = "";
  private ResponseToIgnore = {} as IDictionaryResponseToIgnore;
  private apiTO = environment.apiTimeOut;

  constructor(private _koHelperService : KnowledgeOwlHelperService,
    private elkSearchService: ELKSearchService,
    private koArticleService: KOArticleSearchService,
    private http: Http) {
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
   * Gets all Response to Ignore
   * @param
   */
  public getResponsesToIgnore() {
      this.loadResponsesToIgnore()
    .subscribe(responses=> this.processResponsesToIgnore(responses));
  }

   /**
   * Check if the RESPONSE exists in the  dictionary
   * @param response
   */
  public isResponseToIgnore(response: string): boolean {
    console.log("response ", response.trim())
    for (const key in this.ResponseToIgnore) {
      // console.log (' search ',this.ResponseToIgnore[key].ERROR)

      if (response.indexOf(this.ResponseToIgnore[key].TEXT) !== -1) {
      // if (this.ResponseToIgnore[key].TEXT.trim() == response.trim()) {
        return true;
      }
    }
    return false;
  }

  /**
   * Process the json with all Responses and populate the Tags dictionary
   * @param response
   */
  private processResponsesToIgnore(response) {
    response.forEach((e: ResponseToIgnore) => {
       console.log("Responses To Ignore e: ",e)

      this.ResponseToIgnore[e.TEXT] = e
     });
      console.log("response ",this.ResponseToIgnore)
  }

  /**
   * Gets all Responses To Ignore from DB
   * @param
   */
  private loadResponsesToIgnore() {
    let url = this._apiUrl + '/getResponseToIgnore';
     console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
    .pipe(
      timeout(this.apiTO))
    .map(this.extractData)
    .catch(this.handleError);
  }

  /**
   * Extract data from API response and convert it to json
   * @param res
   */
  private extractData(res: Response) {
    if (res.text()) {
        return res.json().body.data;
    } else {
      return {};
    }
  }
;
  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}

interface IDictionaryResponseToIgnore {
  [index: string]: ResponseToIgnore;
}
