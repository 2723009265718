import { SearchPaginationService } from  '../../../services/SearchPagination/search-pagination';
import { PageStats } from '../../../model/PageStats/PageStats';
import { Component, OnInit, Input, NgZone } from '@angular/core';
import { SearchPagination } from 'src/app/model/SearchPagination/SearchPagination';

@Component({
  selector: 'app-search-pagination',
  templateUrl: './search-pagination.component.html',
  styleUrls: ['./search-pagination.component.css']
})
export class SearchPaginationComponent implements OnInit {
  _page_statsErrors: PageStats;
  _pageArrayErrors: any;
  _ResultsLimitErrors = [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }]
  _ResultsLimitSelectedErrors = 0

  _page_statsFormats: PageStats;
  _pageArrayFormats: any;
  _ResultsLimitFormats = [{ value: 10 }, { value: 25 }, { value: 50 }, { value: 100 }]
  _ResultsLimitSelectedFormats = 0
  _searchPaginationFormats = new SearchPagination
  _searchPaginationErrors = new SearchPagination

  @Input() set ErrorsPageStats(value: PageStats) {
    if (value && value.total_records > 0) {
      this._page_statsErrors = value;
       console.log("Error _page_stats", this._page_statsErrors)
      let max_pages: number
      let ini_page: number
      let max_disp_pages = 10
      if (this._page_statsErrors.total_pages > max_disp_pages) {
        max_pages = max_disp_pages
        if (this._page_statsErrors.current_page - Math.ceil((max_disp_pages/2)) >= 1) {
          if (this._page_statsErrors.current_page + Math.ceil((max_disp_pages/2)) <= this._page_statsErrors.total_pages) {
            ini_page = this._page_statsErrors.current_page - Math.ceil((max_disp_pages/2))
          } else {
            ini_page = this._page_statsErrors.current_page - max_disp_pages + (this._page_statsErrors.total_pages-this._page_statsErrors.current_page) +1;
          }
        } else {
          ini_page =  1;
        }
      } else {
        max_pages = this._page_statsErrors.total_pages
        ini_page = 1;
      }
      this._pageArrayErrors = new Array(max_pages)
      for (let i = 0; i < max_pages; i++) {
        this._pageArrayErrors[i] = ini_page + i
      }
      // console.log("_ResultsLimitErrors ", this._ResultsLimitErrors)
    } else {
      this._page_statsErrors = new PageStats
      this._page_statsErrors.records_per_page = 0;
      this._page_statsErrors.next_page = 0;
      this._page_statsErrors.total_pages = 0;
      this._page_statsErrors.total_records = 0;
    }
  }

  @Input() set FormatsPageStats(value: PageStats) {
    if (value && value.total_records > 0) {
      this._page_statsFormats = value;
      console.log("Format _page_statsFormats", this._page_statsFormats)
      let max_pages: number
      let ini_page: number
      let max_disp_pages = 10
      if (this._page_statsFormats.total_pages > max_disp_pages) {
        max_pages = max_disp_pages
        if (this._page_statsFormats.current_page - Math.ceil((max_disp_pages/2)) >= 1) {
          if (this._page_statsFormats.current_page + Math.ceil((max_disp_pages/2)) <= this._page_statsFormats.total_pages) {
            ini_page = this._page_statsFormats.current_page - Math.ceil((max_disp_pages/2))
          } else {
            ini_page = this._page_statsFormats.current_page - max_disp_pages + (this._page_statsFormats.total_pages-this._page_statsFormats.current_page) +1;
          }
        } else {
          ini_page =  1;
        }
      } else {
        max_pages = this._page_statsFormats.total_pages
        ini_page = 1;
      }
      this._pageArrayFormats = new Array(max_pages)
      for (let i = 0; i < max_pages; i++) {
        this._pageArrayFormats[i] = ini_page + i
      }
      // console.log("_ResultsLimitFormats ", this._ResultsLimitFormats)
    } else {
      this._page_statsFormats = new PageStats
      this._page_statsFormats.records_per_page = 0;
      this._page_statsFormats.next_page = 0;
      this._page_statsFormats.total_pages = 0;
      this._page_statsFormats.total_records = 0;
    }
  }

  constructor(
    private ngZone: NgZone,
    private searchPaginationService: SearchPaginationService) { }

  ngOnInit() {
  }

  onSelectResultLimitErrors(limitIndex: number) {
    if (limitIndex != this._ResultsLimitSelectedErrors) {
      this._ResultsLimitSelectedErrors = limitIndex
      this.GoPageErrors(1)
    }
  }

  onSelectResultLimitFormats(limitIndex: number) {
    if (limitIndex != this._ResultsLimitSelectedFormats) {
      this._ResultsLimitSelectedFormats = limitIndex
      this.GoPageFormats(1)
    }
  }

  GoPageErrors(page: number) {
    this._page_statsErrors.current_page = page
    this._searchPaginationErrors.limit = this._ResultsLimitErrors[this._ResultsLimitSelectedErrors].value
    this._searchPaginationErrors.page = page
    // console.log("page ", page, " limit ", searchPagination.limit)
    this._page_statsErrors.total_records = 0
    this.ngZone.run(() => this.searchPaginationService.newSearchPaginationErrors(this._searchPaginationErrors));
   window.scrollTo(0, 0);
  }

  GoPageFormats(page: number) {
    this._page_statsFormats.total_records = 0
    this._page_statsFormats.current_page = page
    this._searchPaginationFormats.limit = this._ResultsLimitFormats[this._ResultsLimitSelectedFormats].value
    this._searchPaginationFormats.page = page
    // console.log("page Formats", page, " limit Formats ", searchPagination.limit)
    this.ngZone.run(() => this.searchPaginationService.newSearchPaginationFormats(this._searchPaginationFormats));
    window.scrollTo(0, 0);
  }

}
