import { ELKSearchService } from './../../../services/ELKFormatSearchService/elkformat-search.service';
import { SearchPaginationService } from './../../../services/SearchPagination/search-pagination';
import { SearchPagination } from './../../../model/SearchPagination/SearchPagination';
import { PageStats } from '../../../model/PageStats/PageStats';
import { TranslateService } from './../../../services/Translate/translate.service';
import { FormatSearchSelected } from './../../../services/FormatsDB/format-search.service';
import { Format } from './../../../model/FormatDB/Format';
import { Component, OnInit, Output, EventEmitter, NgZone, HostListener } from '@angular/core';
import { FormatSearchService } from 'src/app/services/FormatsDB/format-search.service';
import { NoteService } from './../../../services/FormatsDB/notes.service';
import { BookmarkService } from 'src/app/services/FormatsDB/bookmarks.service';
import { SabreRedMessagesService } from 'src/app/services/SabreRedMessages/sabre-red-messages.service';
import { UserNotificationsService } from 'src/app/services/UserNotifications/user-notifications.service';

declare var $: any;

@Component({
  selector: 'app-format-search',
  templateUrl: './format-search.component.html'
})
export class FormatSearchComponent implements OnInit {
  @Output() IsLoading = new EventEmitter();
  @Output() OnSearchResultsFormatCreated = new EventEmitter();
  @Output() OnSearchResultsElapsedTimeFormats = new EventEmitter();
  @Output() OnSearchResultsFavoriteMode = new EventEmitter();
  @Output() OnSearchFormatsPageStats = new EventEmitter();

  public _isLoading = false;
  private intialTimestamp: number
  private finalTimestamp: number
  private elapsedTime: string
  ShowFavorites = false
  DescriptionSearch = false
  SearchValue = "";
  searchMode = FormatSearchSelected.Start_With;
  private formats: Format[]
  searchParam = 'endsWith'
  _limit = 10
  _page = 1

  constructor(private formatSearchService: FormatSearchService,
    private elkSearchService: ELKSearchService,
    private bookmarkService: BookmarkService,
    private noteService: NoteService,
    private sabreRedMessagesService: SabreRedMessagesService,
    private searchPaginationService: SearchPaginationService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private userNotificationService: UserNotificationsService
  ) { }

  ngOnInit() {
    $('.spark-input').sparkTextInput();
    $('.spark-toggle-switch').sparkToggleSwitch();

    this.sabreRedMessagesService.currentTextSearchFormat.subscribe(message => {
      if (message && message != '') {
        this.SearchValue = message
        // console.log("Search Format Value ", this.SearchValue)
        this.search();
      }
    });
    this.translateService.currentLanguageChange
      .subscribe(language => {
          if (this.SearchValue != '' && this.SearchValue != null) {
            this.search()
          }
    });

    this.searchPaginationService.currentSearchPaginationFormats.subscribe(
      searchPagination => this.OnSearchPaginationRequest(searchPagination)
    );

  }
  OnSearchPaginationRequest(searchPagination: SearchPagination) {
    // console.log("searchPagination Formats search ", searchPagination)
    if (searchPagination && searchPagination.limit) {
      this.search(searchPagination.limit, searchPagination.page);
    }
  }

  /**
   * Search Method
   */
  search(limit?: number, page?:number) {
    // console.log("limit ", limit, " page ", page)
    // console.log("this._limit ", this._limit, " this._page ", this._page)
    let page_stats = new PageStats
    page_stats.total_records = 0
    this.OnSearchFormatsPageStats.emit(page_stats);
    this.OnSearchResultsElapsedTimeFormats.emit("0");

    if (limit==undefined) {
      limit = this._limit
    } else {
      this._limit = limit
    }
    if (page==undefined) {
      page = this._page
    } else {
      this._page = page
    }

    if (this.SearchValue != "") {
      this.ShowFavorites = false;
      this.intialTimestamp = Date.now();

      this._isLoading = true;
      this.IsLoading.emit(this._isLoading);

      // if (!this.DescriptionSearch) {
      // //  this.formatSearchService.searchFormat(this.SearchValue.toUpperCase(), this.searchMode,
      //   this.elkFormatSearchService.searchFormat(this.SearchValue.toUpperCase(), this.searchMode,
      //     limit, page)
      //     .subscribe(
      //       response => this.OnGetFormats(response.data, response.page_stats),
      //       error => this.OnGetFormatsError(error)
      //     );
      // } else {
      //  this.formatSearchService.searchFormatByDescription(this.SearchValue.toLowerCase(), this.searchMode,
        this.elkSearchService.searchFormat(this.SearchValue, limit, page)
          .subscribe(
            response => {
              // response.data.forEach(f => {
              //   let regex = new RegExp(this.SearchValue, 'ig');
              //   f.DESCRIPTION = f.DESCRIPTION.replace(regex, function (x) {
              //     return '<span style="background-color:#FFFF00;">'+x+'</span>';
              //   })
              //   console.log("f.DESCRIPTION ", f.DESCRIPTION)
              // });
              console.log("formats ", response.data)
              this.OnGetFormats(response.data, response.page_stats)
            },
            error => this.OnGetFormatsError(error)
          );
      // }
    }
  }

  setSearchParam(option: string) {
    this.searchParam = option
    switch (option) {
      case 'startsWith':
        this.searchMode = FormatSearchSelected.Start_With;
        break;
      case 'endsWith':
        this.searchMode = FormatSearchSelected.End_With;
        break;
      case 'contains':
        this.searchMode = FormatSearchSelected.Contains;
        break;
    }
  }

  OnGetFormats(formats: Format[], page_stats: PageStats) {

    this._isLoading = false;
    formats.forEach(f => {
      this.loadFormatData(f)
    });

    // console.log("formats ", formats)
    this.finalTimestamp = Date.now();

    this.elapsedTime = ((this.finalTimestamp - this.intialTimestamp) / 1000).toFixed(2);
    this.OnSearchFormatsPageStats.emit(page_stats);

    this.OnSearchResultsFormatCreated.emit(formats);
    this.OnSearchResultsElapsedTimeFormats.emit(this.elapsedTime);
    this.IsLoading.emit(this._isLoading);
    this.OnSearchResultsFavoriteMode.emit(false);
  }

  loadFormatData(f: Format) {
    // console.log(" format ID ", f.ID)
    f.DESCRIPTION = this.elkSearchService.AddDomain(f.DESCRIPTION)
    f.bookmark = this.bookmarkService.isBookmarked(f.ID.toString());
    const note = this.noteService.getNote(f.ID.toString());
    if (note) {
      f.note = note.TEXT;
      f.hasNote = true;
    } else {
      f.hasNote = false;
    }

  }

  OnGetFormatsError(error: any) {
    this._isLoading = false;
    this.IsLoading.emit(this._isLoading);
    this.displayWarningToast();
  }

  displayWarningToast() {
    const notification = this.userNotificationService.createWarningNotification(
      this.translateService.instant('Error'),
      this.translateService.instant('The search did not work. Please try again in a moment'));

    this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
  }

  OpenFavorites() {
    // console.log("this.ShowFavorites ", this.ShowFavorites)
    if (this.ShowFavorites) {
      this._isLoading = true;
      this.IsLoading.emit(this._isLoading);
      let bookmarks = this.bookmarkService.getBookmarks();
      let formats = new Array();
      this.intialTimestamp = Date.now();

      let loaded_formats = false
      //Validate if there are bookmarks
      //Otherwise, turn off isloading
      if(Object.keys(bookmarks).length >= 1){
        for (let b in bookmarks) {
          if (bookmarks[b].TYPE == "F") {
            // console.log(bookmarks[b].PAGE_ID)
            this.elkSearchService.searchFormatById(bookmarks[b].PAGE_ID)
              .subscribe(
                response => {
                  // console.log("response ", response)
                  if (response.length > 0){
                    response.forEach(f => {
                      this.loadFormatData(f)
                      // console.log("data format ", f)
                      formats.push(f)
                      loaded_formats = true
                      this._isLoading = false;
                      this.IsLoading.emit(this._isLoading);

                      let page_stats = new PageStats
                      page_stats.total_records = formats.length
                      page_stats.records_per_page = formats.length
                      page_stats.current_page = 1
                      page_stats.total_pages = 1

                      // console.log ("fav page_stats ", page_stats)
                      this.OnSearchFormatsPageStats.emit(page_stats);

                    })
                  }
                  else{
                    this._isLoading = false;
                  }
                },
                error => this.OnGetFormatsError(error)
              );
          }
        }
    }
    else{
      this._isLoading = false;
      this.IsLoading.emit(this._isLoading);
    }
      // console.log("formats  ", formats)
      // if (loaded_formats) {
      this.finalTimestamp = Date.now();

      this.elapsedTime = ((this.finalTimestamp - this.intialTimestamp) / 1000).toFixed(2);

      let page_stats = new PageStats
      page_stats.total_records = formats.length
      page_stats.records_per_page = formats.length
      page_stats.current_page = 1
      page_stats.total_pages = 1

      // console.log ("fav page_stats ", page_stats)
      this.OnSearchFormatsPageStats.emit(page_stats);
      console.log ("formats before fix ", formats)

      this.OnSearchResultsFormatCreated.emit(formats);
      this.OnSearchResultsElapsedTimeFormats.emit(this.elapsedTime);
      this.OnSearchResultsFavoriteMode.emit(true);
      }
      else {
      // restore the search if there is some search
      if (this.SearchValue != "") {
        this.search()
      }
      else {
        this.OnSearchResultsFormatCreated.emit(new Array());
        this.OnSearchResultsElapsedTimeFormats.emit(0);
        this.IsLoading.emit(this._isLoading);
        this.OnSearchResultsFavoriteMode.emit(false);
      }
    }
  }

  switchFavorite() {
    this.ShowFavorites = !this.ShowFavorites;
    this.OpenFavorites();

  }

  switchDescription() {
    this.DescriptionSearch = !this.DescriptionSearch;
    if (this.DescriptionSearch) {
      this.setSearchParam('contains')
    }
  }

  OnLoadFormats(formats: Format[]) {
    formats.forEach(f => {
      this.loadFormatData(f)
    })
  }

  @HostListener('document:keyup', ['$event'])
  handleInput(event: KeyboardEvent) {
    //console.log("key value " + event.key)
    switch (event.key){
      case '\'':
          this.SearchValue = this.SearchValue.replace('\'','¥');
      break;
      case '[':
          this.SearchValue = this.SearchValue.replace('[', '¤');
      break;
      case '=':
          this.SearchValue = this.SearchValue.replace('=', '*');
      break;
      case '\\':
          this.SearchValue = this.SearchValue.replace('\\', '§');
      break;
      case '`':
          this.SearchValue = this.SearchValue.replace('`', '^');
      break;
      case ']':
          this.SearchValue = this.SearchValue.replace(']', '¶');
      break;
    }
  }

}
