import { Injectable } from '@angular/core';
import { Subject   } from 'rxjs/Subject';
import {User} from '../../model/RedApp/User';

/**
 * Sabre Red Workspace API
 */
declare var SrwOSGiApi : any;
declare var SrwApi : any;
declare var OpenModal : any;

@Injectable({
  providedIn: 'root'
})

/***
 * This Service Handles nSRW Authentication
 */
export class AuthenticationService {

  IsAutenticated = false;
  NeedsToSignIn = false;
  NotAllowedToUseApp = false;
  AuthenticationFailed = false;
  User = new User();
  private authenticationChangedSource = new Subject();
  public authenticationChangedEvent = this.authenticationChangedSource.asObservable();

  constructor() {

  }

  /**
   *  Authenticate User Using Sabre Red Workspace API
   */
  authenticate(){
    //console.log('Authenticating');
    try{
      if(SrwOSGiApi){
        this.User.FirstName = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.platform.core.sso.base.IAgentProfileService","getFirstName", null)).string;
        this.User.LastName = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.platform.core.sso.base.IAgentProfileService","getLastName", null)).string;
        this.User.Email = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.platform.core.sso.base.IAgentProfileService","getEmail", null)).string;
        this.User.PCC = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.platform.core.sso.base.IAgentProfileService","getPcc", null)).string;
        this.User.EPR = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.platform.core.sso.base.IAgentProfileService","getAgentId", null)).string;
        this.User.Language = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.platform.core.sso.base.IAgentProfileService","getLanguage", null)).string;
        // this.User.Token = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.cf.core.token.IAuthenticationTokenService","getToken", null)).string;
        // let token2 = eval( "(" + this.User.Token + ")" ).string;
        let token2 = this.User.Token;
        // let jsonString = "{\"com.sabre.edge.cf.model.element.ServiceContext\":{\"serviceRequired\":true,\"status\":\"CREATED\",\"contextName\":\"com.sabre.edge.cf.host.Communication\",\"request\":{\"@class\":\"com.sabre.edge.cf.host.data.HostCommand\",\"lockId\":-1,\"text\":\""+command+"\"},\"requestorId\":[\"example-browser-tgbz6k\"],\"token\":\""+token2+"\"}}";
        // console.log('jsonString ', jsonString)
        var rspHostATH = JSON.parse(SrwApi.host("OIATH"));
        // console.log('rsp ', rspHostATH)
        console.log('User', this.User)

        if (rspHostATH.response.success && rspHostATH.response.payload) {
          if (!rspHostATH.response.payload.text.includes('IGN IN A') &&
          !rspHostATH.response.payload.text.includes('IS NOT SIGNED IN')) {
            this.User.Token = rspHostATH.response.payload.text
            this.IsAutenticated = true;
            // console.log('user ', this.User)
            // console.log('User authenticated');
          } else {
            this.NeedsToSignIn = true;
          }
        } else {
          this.NeedsToSignIn = true;
        }

        var rspHostPNAPNR = JSON.parse(SrwApi.host("W/PNAPNR¥*"));
        console.log('rspHostPNAPNR', rspHostPNAPNR)

        console.log('User', this.User)
        // let ATHToken = JSON.parse(SrwOSGiApi.callOSGIService("com.sabre.edge.cf.core.registry.service.ISRWRuntime","processJSON",jsonString));
  //      OpenModal.OpenSimpleModal('Hello ' + this.User.FirstName + ' ' + this.User.LastName);
      }else{
        this.AuthenticationFailed = true;
        // console.log('Authentication Failed');
      }
    }
    catch{
      this.NotAllowedToUseApp = true;
    }
    this.authenticationChangedSource.next();
  }


  getCurrentUser() {
    return this.User
  }
}
