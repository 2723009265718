/**
 * PROD ENVIRONMENT CONFIGURATION FILE
 */
export const environment = {
  production: true,
  // apiUrl : 'https://quick.tn.dev.asc.sabre.com/api',
  // apiUrl : 'https://quick.sabre.com/api',
  apiUrl : 'https://quick.sabre.com/api',
  SFDCURL: 'https://tncommdev-sabreservice.cs79.force.com/s/',

  KO_PW: '$tr0ngT5mpPa$word',
  apiTimeOut: 20000,
  SF_ARTICLES: 'https://central.sabre.com',
  AZURE_URL: 'https://login.microsoftonline.com/03ceccf2-fe27-4c66-abdb-699141848e61/oauth2/v2.0/authorize?client_id=7241a430-8463-4c57-aaea-634ffc586a41&response_type=code&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&response_mode=query&redirect_uri=https://dev.set.sabre.com',
  AZURE_CLIENT_ID: '7241a430-8463-4c57-aaea-634ffc586a41'

  // apiUrl : 'https://gtmuapp01.sgdcprod.sabre.com:8443/api'
};
