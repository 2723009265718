import { PageStats } from '../../../model/PageStats/PageStats';
import { Http } from '@angular/http';
import { TranslateService } from '../../../services/Translate/translate.service';
import { NoteService } from '../../../services/FormatsDB/notes.service';
import { KOUserService } from '../../../services/KnowledgeOwl/kouser.service';
import { BookmarkService } from '../../../services/FormatsDB/bookmarks.service';
import { Component, OnInit, Input, Output, EventEmitter, SimpleChange, NgZone, HostListener } from '@angular/core';
import { Article } from 'src/app/model/KnowledgeOwl/Article';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { UserNotificationsService } from 'src/app/services/UserNotifications/user-notifications.service';
import { AppSettingsService } from 'src/app/services/Settings/app-settings.service';
import { Format } from 'src/app/model/FormatDB/Format';
import { ErrorArticle } from 'src/app/model/FormatDB/ErrorArticle';
import { ELKSearchService } from 'src/app/services/ELKFormatSearchService/elkformat-search.service';
import { RestAPIRegisterService } from 'src/app/services/RestAPI/register.service';

declare var $: any;

@Component({
  selector: 'app-search-result-error',
  templateUrl: './search-result-error.component.html',
  styleUrls: ['./search-result-error.component.css']
})
export class SearchResultErrorComponent implements OnInit {
  _errorList: ErrorArticle[];
  ShowKOArticleModal = false;
  ShowArticleUrl = "";
  DisplayNote = "none";
  currentItem: number;
  viewMoreItem: number;
  viewMoreItemPrevious = -1;
  NoteText = "";
  userId: string;
  DisplayViewMore = "inline-block"
  DisplayViewLess = "none"
  viewMoreSelected = false;
  ViewModeLabels = ["View More", "View Less"];
  ViewMode = this.ViewModeLabels[0];
  articleBody = "";
  Loading = false;
  loadingText = ""
  CSS_CodePre = ""
  articlesMultiCause: articleMC[][] = []
  sabreCentralActive = false;
  _elapsedTime;

  @Input() set Errors(value: ErrorArticle[]) {
    if (!this.userId && this.koUserService.getCurrentUser()) {
      this.userId = this.koUserService.getCurrentUser().USER_ID;
    }

    if (value && value != undefined) {
      this._errorList = value;
      console.log('_articleList', value)
      this.articlesMultiCause = new Array(this._errorList.length);

      for (let i = 0; i < this._errorList.length; i++) {
        this._errorList[i].DESCRIPTION = this.elkSearchService.AddDomain(this._errorList[i].DESCRIPTION)
        this._errorList[i].EXAMPLE = this.elkSearchService.filterChar(this._errorList[i].EXAMPLE)

        this._errorList[i].isMultiCause = this.isMultipleCause(this._errorList[i].DESCRIPTION);

        if (this._errorList[i].isMultiCause) {
          // console.log("call loadArticle from input")
          //this.articleBody =
          this.loadArticleBody(this._errorList[i].DESCRIPTION, i, false, 0);
          //this.loadArticle(i);
        }
      }
      if (this._errorList.length ==1) {
       this.articleBody = this._errorList[0].DESCRIPTION
      }
    }
  }

  @Input() set ElapsedTimeErrors(value: string) {
    if (value && value != undefined) {
      this._elapsedTime = value;
    } else {
      this._elapsedTime = "0";
    }
    if (!this.userId && this.koUserService.getCurrentUser()) {
      this.userId = this.koUserService.getCurrentUser().USER_ID;
    }

  }

  constructor(
    private bookmarkService: BookmarkService,
    private noteService: NoteService,
    private elkSearchService: ELKSearchService,
    private koUserService: KOUserService,
    private translate: TranslateService,
    private http: Http,
    public sanitizer: DomSanitizer,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private userNotificationService: UserNotificationsService,
    private restAPIRegisterService: RestAPIRegisterService,
    private appSettingsService: AppSettingsService
    ) { }




  ngOnInit() {
    $('.spark-expand').sparkExpand();
    $('.spark-popover').sparkPopover();
    $('.spark-input').sparkTextInput();


    this.http.get("assets/css/code-pre.html")
      .subscribe(data => {
        if (data.text()) {
          this.CSS_CodePre = data.text()
          // console.log("this.CSS_CodePre ", this.CSS_CodePre)
        }
      });
      if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled")) {
        if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled").indexOf('True') >=0){
          this.sabreCentralActive = true;
        }
      }
    }

  ngOnChanges(event: SimpleChange) {
    this.loadingText = this.translate.instant("Loading") + "..."
    //this.articlesMultiCause.length = 0

    if (this._errorList.length == 1) {
      this.expandViewMore(0)
    } else {
      this.DisplayNote = "none";
      this.currentItem = null;
      this.viewMoreItem = null;
      this.NoteText = "";
      this.DisplayViewMore = "inline-block"
      this.DisplayViewLess = "none"
      this.viewMoreSelected = false;
      this.ViewMode = this.ViewModeLabels[0];
    }

  }

  createBookmark(article: Article) {
    let pageId = article.id;
    let type = 'A';
    if (article.bookmark) {
      this.bookmarkService.deleteBookmark(this.userId, pageId, type);
      article.bookmark = false;
    } else {
      this.bookmarkService.createBookmark(this.userId, pageId, type);
      article.bookmark = true;
    }
  }

  saveNote() {
    let pageId = this._errorList[this.currentItem].ID.toString();
    let type = 'A';

    if (this._errorList[this.currentItem].hasNote) {
      this.noteService.editNote(this.userId, pageId, type, this.NoteText);
    } else {
      this.noteService.createNote(this.userId, pageId, type, this.NoteText);
      this._errorList[this.currentItem].hasNote = true
    }

    this._errorList[this.currentItem].note = this.NoteText;

    this.closeNote();
  }

  deleteNote() {
    let pageId = this._errorList[this.currentItem].ID.toString();
    let type = 'A';

    if (this._errorList[this.currentItem].hasNote) {
      this.noteService.deleteNote(this.userId, pageId, type);
      this._errorList[this.currentItem].hasNote = false
      this._errorList[this.currentItem].note = ""
    }
    this.closeNote();
  }

  closeNote() {
    this.currentItem = null;
    this.NoteText = ""
  }

  openNote(article: Article, i: number) {
    this.DisplayNote = "block"
    this.currentItem = i
    this.NoteText = article.note
  }

  viewMore(i: number) {
    // console.log("start this.viewMoreItemPrevious ", this.viewMoreItemPrevious, " this.viewMoreItem ", this.viewMoreItem, " i ", i)

    // current card
    if (this.viewMoreItemPrevious == i) {
      if (this.ViewMode == this.ViewModeLabels[0]) {
        this.expandViewMore(i)
      } else {
        this.collapseViewMore(i)
      }
    } else {
      this.collapseViewMore(i)
      this.expandViewMore(i)
    }
    // console.log("end this.viewMoreItemPrevious ", this.viewMoreItemPrevious, " this.viewMoreItem ", this.viewMoreItem)
  }

  collapseViewMore(i: number) {
    this.DisplayViewMore = "inline-block"
    this.DisplayViewLess = "none"
    this.viewMoreItem = i
    this.viewMoreSelected = false;
    this.ViewMode = this.ViewModeLabels[0];
  }

  expandViewMore(i: number) {
    this.viewMoreItemPrevious = i

    this.DisplayViewMore = "none"
    this.DisplayViewLess = "inline-block"
    this.viewMoreItem = i
    this.viewMoreSelected = true;
    this.ViewMode = this.ViewModeLabels[1];

    let ref = "/s/article/";
    this.articleBody = this._errorList[i].DESCRIPTION
    // if (!this._errorList[i].DESCRIPTION || this._errorList[i].DESCRIPTION == undefined) {
    //   this.loadArticle(i);
    // } else {
    //   this.articleBody = this._errorList[i].DESCRIPTION
    // }

  }

  OnGetKoBodyArticles(article): string {
    let body = "";
    if (article.current_version.en.text) body = article.current_version.en.text;

    return body;
  }

  viewLess(i: number) {
    this.DisplayViewMore = "inline-block"
    this.DisplayViewLess = "none"
    this.viewMoreItem = i
    this.viewMoreSelected = false;

    this.viewMoreItemPrevious = i
  }

  getViewMode(i: number): string {

    let text: string;
    if (i == this.viewMoreItem) {
      text = this.ViewMode
    } else {
      text = this.ViewModeLabels[0]
    }

    return text;

  }

  loadArticleBody(bodyHTML: string, card: number, loadSubArticle: boolean, indexMC: number): string {
    // console.log("enter loadArticleBody ", bodyHTML)
    let body = bodyHTML;
    let bodyChanged = false

    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(body, 'text/html');
    let aElements = parsedHtml.getElementsByTagName("a");
    // console.log("length ", this.articlesMultiCause[card].length)
    if (!this.articlesMultiCause[card]) {
      this.articlesMultiCause[card] = new Array();
    }
    // console.log("aElements ",aElements)
    if (aElements && aElements.length > 0) {
      console.log("parseHtml: ", parsedHtml)
      for (let i = 0; i < aElements.length; i++) {
        let item = aElements[i].toString();
        console.log("elem: ", item)
        if (item.startsWith("http")) {
          bodyChanged = true
          // console.log("aElements: ", aElements[i].parentElement.tagName)
          // console.log("aElements: ", aElements[i].text)
          // console.log("aElements: ", aElements[i].pathname)
          let url = aElements[i].pathname.split("/s/article/")
          console.log("url: ", url)

          let el = new articleMC(aElements[i].text, '', false, false, [], [], url[1], [])
          console.log (" el object ",el)
          if (loadSubArticle) {
            this.articlesMultiCause[card][indexMC].subArticles.push( el)
            //this.articlesMultiCause[card][indexMC].subArticles.splice(this.articlesMultiCause[card][indexMC].subArticles.length, 0, el)
          } else {
            this.articlesMultiCause[card].push( el)
            // this.articlesMultiCause[card].splice(this.articlesMultiCause[card].length, 0, el)
          }
        }
      }
      console.log("MC ", this.articlesMultiCause)
      // console.log("bodyChanged ", bodyChanged)
      if (bodyChanged) body = ""
    }
    if (body != "") {
      body = this.checkForHref(body)
    }
    return body;
  }

  viewAMC(card: number, articleMC: number) {
    // console.log("card ", card, "articleMC", articleMC)
    this.articlesMultiCause[card][articleMC].expanded = !this.articlesMultiCause[card][articleMC].expanded
    for (let i = 0; i < this.articlesMultiCause[card].length; i++) {
      if (i != articleMC) {
        this.articlesMultiCause[card][i].expanded = false;
      }
    }
    // console.log("Loading  card ", card, " articleMC ", articleMC, " body ", this.articlesMultiCause[card][articleMC].DESCRIPTION)
    if ((!this.articlesMultiCause[card][articleMC].DESCRIPTION || this.articlesMultiCause[card][articleMC].DESCRIPTION == undefined)
      && this.articlesMultiCause[card][articleMC].subArticles.length == 0) {
      // console.log("Loading  body")

      this.articlesMultiCause[card][articleMC].DESCRIPTION = "<br><b>" + this.loadingText + "</b>"
      this.articlesMultiCause[card][articleMC].loading = true
      this.loadMultiCauses(card, articleMC, this.articlesMultiCause[card][articleMC].url_hash, -1);
    }
  }

  loadMultiCauses(card: number, articleMC: number, url: string, currentSubMCIndex: number) {

    let art: any;
    this.elkSearchService.searchErrorByURL(url)
      .subscribe(
        response => {
            console.log("responde ", response)
            art = response[0];
          if (art) {
            // console.log("art ", art, " currentSubMCIndex ", currentSubMCIndex)
            art.DESCRIPTION = this.elkSearchService.AddDomain(art.DESCRIPTION);
            let body = art.DESCRIPTION;

            // it doesn't have subArticles
            if (currentSubMCIndex == -1) {
              // console.log("isMultipleCause ", this.koArticleService.isMultipleCause(art.tags))


              this.articlesMultiCause[card][articleMC].isArticle = !this.isMultipleCause(art.DESCRIPTION)


              let loadSubArticle = !this.articlesMultiCause[card][articleMC].isArticle

              if (this.articlesMultiCause[card][articleMC].isArticle) {
                this.articlesMultiCause[card][articleMC].DESCRIPTION = this.checkForHref(body)
              } else {
                this.articlesMultiCause[card][articleMC].DESCRIPTION = this.loadArticleBody(body, card, loadSubArticle, articleMC)
              }
              this.articlesMultiCause[card][articleMC].tags = art.tags
              // if (art.tags) {
              //   for (let i = 0; i < art.tags.length; i++) {
              //     this.articlesMultiCause[card][articleMC].tagsname.push(this.koTagsService.getTag(art.tags[i]).name)
              //   }
              // }
              this.articlesMultiCause[card][articleMC].loading = false
            } else {
              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].isArticle = true
              let loadSubArticle = !this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].isArticle

              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].DESCRIPTION = this.loadArticleBody(body, card, loadSubArticle, articleMC)
              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].tags = art.tags
              // if (art.tags) {
              //   for (let i = 0; i < art.tags.length; i++) {
              //     this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].tagsname.push(this.koTagsService.getTag(art.tags[i]).name)
              //   }
              // }
              this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].loading = false
            }
          } else {
            this.articlesMultiCause[card][articleMC].DESCRIPTION = "Article not found!"
          }
        },
      ),
      error => {
        this.OnError(error)
      };
    // console.log("this.articlesMultiCause[card][articleMC] ", this.articlesMultiCause[card][articleMC])
  }

  viewSubAMC(card: number, articleMC: number, currentSubMCIndex: number) {
    // console.log("card ", card, "articleMC", articleMC, " currentSubMCIndex ", currentSubMCIndex)
    this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].expanded = !this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].expanded
    for (let i = 0; i < this.articlesMultiCause[card][articleMC].subArticles.length; i++) {
      if (i != currentSubMCIndex) {
        this.articlesMultiCause[card][articleMC].subArticles[i].expanded = false;
      }
    }
    // console.log("Loading  card ", card, " articleMC ", articleMC, " currentSubMCIndex ", currentSubMCIndex, " body ", this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].DESCRIPTION)
    if (!this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].DESCRIPTION ||
      this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].DESCRIPTION == undefined) {
      // console.log("Loading  body")
      this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].loading = true
      this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].DESCRIPTION = "<br><b>" + this.loadingText + "</b>"
      this.loadMultiCauses(card, articleMC, this.articlesMultiCause[card][articleMC].subArticles[currentSubMCIndex].url_hash, currentSubMCIndex);
    }
  }

  checkForHref(body: string): string {
    let stringHRef = '<a href'
    let hRefArray = body.split(stringHRef)
    // console.log("hRefArray[0] ",hRefArray[0])
    let changedBody = hRefArray[0]
    for (let i = 1; i < hRefArray.length; i++) {
      let stringToReplace = hRefArray[i]
      // console.log("stringToReplace ",stringToReplace)

      changedBody += '<a target="_blank" href'
      // get the url from the string
      let URLArray = stringToReplace.split('"')

      let url = URLArray[1].split("quick.knowledgeowl.com/help/")
      // console.log("url[0]",url[0])
      // console.log("url[1]",url[1])

      if (url.length > 1) {
        stringToReplace = stringToReplace.replace(URLArray[1], this.createLinkToSFDC(url[1]))
      }
      // console.log("stringToReplace modified",stringToReplace)
      changedBody += stringToReplace
    }

    // console.log("changedBody ", changedBody)
    return changedBody
  }
  containsLinks(bodyHTML: string): boolean {
    // console.log("containsLinks  ", bodyHTML)

    let parser = new DOMParser();
    let parsedHtml = parser.parseFromString(bodyHTML, 'text/html');
    let aElements = parsedHtml.getElementsByTagName("a");
    // console.log("aElements ",aElements)
    if (aElements && aElements.length > 0) {
      // console.log("parseHtml: ", parsedHtml)
      for (let i = 0; i < aElements.length; i++) {
        if (aElements[i].parentElement.tagName == "LI") {
          return true;
        }
      }
    }
    return false;
  }


  closeKOArticleModal() {
    this.ShowKOArticleModal = false;
  }

  openKOArticleModal(article: Article) {
    this.ShowArticleUrl = 'https://quick.knowledgeowl.com/help/' + article.url_hash;
    this.ShowKOArticleModal = true;
    // console.log('Showing ' + article.name + ' in modal');
  }

  viewMoreInSalesforceCommunity(article: Article) {
    if (this.sabreCentralActive){
      window.open(this.createLinkToSFDC(article.url_hash), '_blank');
    }
  }

  createLinkToSFDC(param: string): string {
    let url;
    if (param == null){
      url = environment.SFDCURL + "finder" + "?mode=embedded";
    }
    else{
      url = environment.SFDCURL +"article/"+ param + "?mode=embedded";
    }
    //console.log('SFDC url ', url)
    return url
  }

  OnError(error: any) {
    this.displayWarningToast();
  }

  displayWarningToast() {
    const notification = this.userNotificationService.createWarningNotification(
      this.translateService.instant('Error'),
      this.translateService.instant('Cannot display the Article. Please try again in a moment'));

    this.ngZone.run(() => this.userNotificationService.newUserNotification(notification));
  }


  isMultipleCause(str : string) : boolean {
    let result = false;

    result = str.includes('<ul><li><a');

    return result;

  }
  @HostListener('document:click', ['$event.target'])

  //@HostListener('document:click')

  linkSF(event) {

    if (event) {
      let url = event.toString()
      console.log('linkSF Clicked ', url)

      let article = url.split("/s/article/")

      if (article && article[1]) {
        console.log('article', article[1])

        this.restAPIRegisterService.setSFClickedLink(article[1]).subscribe(
          response => console.log(response),
          error => console.log(error)
        );
      }
    }
  }


}

class articleMC {
  text: string;
  DESCRIPTION: string;
  expanded: boolean;
  isArticle: boolean;
  tags: string[];
  tagsname: string[];
  url_hash: string;
  subArticles: articleMC[];
  loading: boolean;

  constructor(text, body, expanded, isArticle, tags, tagsname, url_hash, subArticles) {
    this.text = text;
    this.DESCRIPTION = body;
    this.expanded = expanded
    this.isArticle = isArticle
    this.tags = tags
    this.tagsname = tagsname
    this.url_hash = url_hash
    this.subArticles = subArticles
    this.loading = false
  }

}
