import { PageStats } from '../../../model/PageStats/PageStats';
import { SabreRedMessagesService } from './../../../services/SabreRedMessages/sabre-red-messages.service';
import { Format } from './../../../model/FormatDB/Format';
import { Article } from 'src/app/model/KnowledgeOwl/Article';
import { Component, OnInit, NgZone } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/Settings/app-settings.service';
import { ErrorArticle } from 'src/app/model/FormatDB/ErrorArticle';
declare var $: any;

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html'
})
export class SearchComponent implements OnInit {
  ResultErrors: ErrorArticle[] = new Array();
  ResultFormats: Format[] = new Array();
  ElapsedTimeFormats: string;
  ElapsedTimeErrors: string;
  FavoriteMode: boolean;
  ErrorsPageStats: PageStats;
  FormatsPageStats: PageStats;
  public _isLoading = false;
  public tabSelected: any;
  activeFormats = true;
  sabreCentralActive = false;

  /**
   *
   * @param sabreRedMessagesService Sabre Red Message Service
   */
  constructor(
    private sabreRedMessagesService: SabreRedMessagesService,
    private appSettingsService: AppSettingsService) {

  }

  ngOnInit() {
    window.history.pushState('', '', '/');
    this.activeFormats = true
    $('.spark-tabs').sparkTabs();
    $('.spark-modal').sparkModal();
    this.sabreRedMessagesService.currentArticleSearchError.subscribe(
      errorArticle => this.OnSearchErrorExternalRequest(errorArticle)
    );
    if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled")) {
      if (this.appSettingsService.getSpecificAppSetting("SabreCentralLinksEnabled").indexOf('True') >=0){
        this.sabreCentralActive = true;
      }
    }
  }

  ngAfterViewInit(){

  }

  OnSearchResultsErrorCreated(ErrorsResponse: ErrorArticle[]) {
    this.ResultErrors = ErrorsResponse;
  }

  OnSearchResultsFormatCreated(FormatResponse: Format[]) {
    this.ResultFormats = FormatResponse;
  }
  OnSearchResultsElapsedTimeFormats(elapsedTime: string) {
    this.ElapsedTimeFormats = elapsedTime;
  }

  OnSearchResultsElapsedTimeErrors(elapsedTime: string) {
    this.ElapsedTimeErrors = elapsedTime;
  }

  OnSearchResultsFavoriteMode(favoriteMode: boolean) {
    this.FavoriteMode = favoriteMode;
  }
  IsLoading(isLoading: boolean) {
    this._isLoading = isLoading;
  }

  OnSearchErrorsPageStats(page_stats: PageStats) {
    this.ErrorsPageStats = page_stats;
  }

  OnSearchFormatsPageStats(page_stats: PageStats) {
    this.FormatsPageStats = page_stats;
  }

  toggleActiveFormats(formatactive: boolean){
    this.activeFormats = formatactive;
  }

  OnSearchErrorExternalRequest(errorArticle: ErrorArticle) {
    console.log('OnSearchErrorExternalRequest ', errorArticle)
    if (errorArticle && errorArticle.DESCRIPTION) {
      this.activeFormats = false;
      let articles = []
      articles.push(errorArticle)
      let page_stats = new PageStats()
      page_stats.records_per_page = 1;
      page_stats.next_page = 1;
      page_stats.total_pages = 1;
      page_stats.total_records = 1;
      page_stats.current_page = 1;

      this.OnSearchErrorsPageStats(page_stats);
      this.OnSearchResultsErrorCreated(articles)
      this.OnSearchResultsElapsedTimeErrors("1.00");
      // this._isLoading = true;
      // this.IsLoading(this._isLoading);
    // console.log('change to Error search', article);
    }
  }

  OpenSabreCentral() {
    if (this.sabreCentralActive){
      window.open(environment.SFDCURL + 'support?mode=embedded', '_blank');
    }
  }
}
