import { ArticleResults } from './../../model/KnowledgeOwl/ArticleResults';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Observable } from 'rxjs/RX';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/do';
import { Article } from '../../model/KnowledgeOwl/Article';
import { KnowledgeOwlHelperService } from '../knowledge-owl-helper.service';
import { PageStats } from 'src/app/model/PageStats/PageStats';
import { environment } from 'src/environments/environment';
import { timeout } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class KOArticleSearchService {

  private _apiUrl = "";
  private apiTO = environment.apiTimeOut;

  constructor(private _koHelperService: KnowledgeOwlHelperService, private http: Http) {
    this._apiUrl = _koHelperService.getUrl();
  }

  /**
   * Call ProxyServer API to get KO Articles using query string.
   * This uses suggest API which provide a summary text but not the whole article body text  
   * @param queryText, groups 
   */
  public searchArticle(queryText: string): Observable<Article[]> {
    let url = this._apiUrl + '/searchArticles?phrase=' + queryText;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .map(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Call ProxyServer API to get KO Articles from Errors category using query string and 
   * list of groups of the current user
   * @param queryText, groups 
   */
  public searchErrors(queryText: string, groups: string[], limit: number, page: number): Observable<ArticleResults> {

    // search for a specific category in KO related to ERRORS
    let url = this._apiUrl + '/searchArticles?phrase=' + '"' + queryText + '"';
    url += "&parents=5b9aaed5ad121c6d4b8ac6cb";
    url += "&partial_search=false";
    let reader_groups: string;
    if (groups != null && groups.length > 0) {
      groups.forEach(g => {
        if (!reader_groups) {
          reader_groups = '["' + g + '"';
        } else {
          reader_groups += ',"' + g + '"';
        }
      });
      if (reader_groups) {
        reader_groups += "]";
        url += "&reader_groups=" + reader_groups;
      }
    }
    url += "&limit=" + limit + "&page=" + page;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .map(this.extractArticleResults)
      .do(results => {
        if (results.page_stats) {
          results.page_stats.current_page = page
        } else {
          results.page_stats = new PageStats
          results.page_stats.total_records = 0
          results.page_stats.total_pages = 0
        }
      })
      .catch(this.handleError);
  }

  /**
   * Call ProxyServer API to get KO Article using Article id
   * Uses Article Endpoint which provides the full article object
   * @param id 
   */
  public getArticle(id: string): Observable<Article[]> {
    let url = this._apiUrl + '/getArticle?articleId=' + id;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .map(this.extractData)
      .catch(this.handleError);
  }

  /**
   * Call ProxyServer API to get KO Articles using query string.
   * This uses suggest API which provide a summary text but not the whole article body text  
   * @param queryText, groups 
   */
  public getArticleByURL(url_hash: string): Observable<Article[]> {
    let url = this._apiUrl + '/findArticles?url_hash=' + url_hash;
    // console.log("url ", url)
    return this.http.get(url, this._koHelperService.getHeaders())
      .pipe(
        timeout(this.apiTO))
      .map(this.extractData)
      .catch(this.handleError);
  }


  /**
   * Extract data from API response and convert it to json
   * @param res 
   */
  private extractData(res: Response) {
    if (res.text()) {
      const jsonResp = res.json();
      // console.log("jsonResp ", jsonResp)
      if (jsonResp.body.valid) {
        // console.log("article: ", jsonResp.body.data)
        return jsonResp.body.data;
      } else {
        return {};
      }
    } else {
      return {};
    }
  }

  /**
   * Extract data from API response and convert it to json
   * @param res 
   */
  private extractArticleResults(res: Response): ArticleResults {
    let articleResults: ArticleResults
    if (res.text()) {
      const jsonResp = res.json();
      // console.log("jsonResp ", jsonResp)
      articleResults = jsonResp.body;
      if (articleResults.valid) {
        // console.log("articleResults: ", articleResults)
        return articleResults;
      } else {
        return articleResults;
      }
    } else {
      return articleResults;
    }
  }


  isMultipleCause(tags: string[]): boolean {

    if (!tags || tags.length == 0) return false;
    return tags.indexOf('5c619e2eec161c7551683d00') >= 0;

  }


  private handleError(error: any) {
    //console.log("Error " + error);
    let errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error';
    return Observable.throw(errMsg);
  }
}
